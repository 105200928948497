import React from 'react'
import Page1 from './components/Page1/Page1'
import CardContainer from './components/ CardContainer/CardContainer'

const PublicSale = () => {
  return (
    <>
      <Page1 />
      {/* <CardContainer /> */}
    </>
  )
}

export default PublicSale