import React from 'react'
import styled from 'styled-components'
import Web3 from 'web3';
import eth from './eth.svg'

const Hb = styled.p`
    
 
      font-weight: 400;
        color: #FFFFFF;
        font-size: 2.5rem;
        
        margin: 0.5rem 0 0 0.5rem;
    @media only screen and (max-width: 768px) {
      
        font-size: 1.5rem;
      }
`;

export const T = styled.p`
    color: #FFF;
    letter-spacing: 0.06rem;
    font-weight: lighter;
`


const StyledBid = styled.div`
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 800px) {
      
          width: 80%;
        }
        @media only screen and (max-width: 768px) {
      
          width: 90%;
        }
        @media only screen and (max-width: 630px) {
      
          flex-direction: column;
        }
    `

const MyBid = ({myRank,myContribution}) => {

    



  return (
    <StyledBid>
        <div style={{margin:'1rem 0 0 0'}}>
            <T>My Rank</T>
            <div style={{display:'flex',}}> 
                {/* <img src={eth} alt='' style={{height: '2.5rem'}}/> */}
                <Hb>#{myRank}</Hb>
            </div>
        </div>
        <div style={{margin:'1rem 0 0 0'}}>
            <T>My Contribution</T>
            <div style={{display:'flex',}}> 
                <img src={eth} alt='' style={{height: '2.5rem'}}/>
                <Hb>{Web3.utils.fromWei(myContribution.toString())} ETH</Hb>
            </div>
        </div>



        
    </StyledBid>
  )
}

export default MyBid