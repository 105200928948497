
import  {MerkleTree} from "merkletreejs"
import keccak256  from "keccak256"
import addresses from "./whitelist.json";

let leaves = addresses.map(addr => keccak256(addr))

let merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true})
let rootHash = merkleTree.getRoot().toString('hex')
console.log({refundMerkel:rootHash});



export const getMyRootHash=(address)=>{
    const hashedAddress = keccak256(address)
   return merkleTree.getHexProof(hashedAddress)
}
