import React,{ useState, useEffect} from 'react'
import styled from 'styled-components'
import './Bg.css'
 import bg from './bg.png'
 import chi from './chimp.png'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from "react-redux";
import { BsBoxArrowUp } from 'react-icons/bs';
import { Link, animateScroll as scroll } from "react-scroll";
import btn from './btn.png'
import mbg from './mbg.jpeg'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const HomePage = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
  width: 100%;
  background-image: url(${isMobile?mbg:bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #000;

  @media only screen and (max-width: 1500px) {
    background-size: auto 100%;
    background-position: center center;
}

@media only screen and (max-width: 768px) {
    min-height: 100vh;
    // background-image: url(${mbg});
   // background-position: 20rem center;
    width: 100%;
    padding: 0rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
}`

const Width = styled.div`
      width: 1600px;
      display: flex;
        
        align-items: center;
        justify-content: space-between;
      @media only screen and (max-width: 1700px) {
        width: 90%;
      }
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const T = styled.p`
    color: #FFFFFF ;
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 400;
    font-size: 26.6px;
    margin-bottom: 2rem;
    text-align: right;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const ShowButton = styled(Link)`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background: linear-gradient(97.53deg, #C429E0 18.8%, #812BE1 89.56%); 
    color: #FFF ;
    font-weight: bold;
    cursor: pointer;
    position: fixed;
    height: 4rem;
    width: 3rem;
    border-radius: 0.5rem;
    transition:  0.5s linear;
    right: 3rem ;
    bottom: 10rem;
    display: grid;
    place-items: center;
    z-index: 7;
    @media only screen and (max-width: 768px) {
      right: 2rem ;
    bottom: 5rem;
    }
    
`


const Left = styled(motion.div)`
        width: 45%;
       display: flex;
       flex-direction: column;
       align-items: flex-end;
          @media only screen and (max-width: 1100px) {
            width: 60%;
        }
        @media only screen and (max-width: 998px) {
            width: 85%;
        }

    @media only screen and (max-width: 768px) {
     
        width: 85%;
        height: 20rem;
       
      }
`;

const Right = styled.div`
   
    width: 50%;

      @media only screen and (max-width: 1100px) {
            width: 60%;
           
        }
        @media only screen and (max-width: 998px) {
            width: 85%;
           
        }

      @media only screen and (max-width: 768px) {
        width: 95%; 
        
      }
`;


const Image = styled.div``

const StyleButton = styled.div`
  background-image: url('${btn}');
  background-size: 100% 100%;
  width: 18rem;
  height: 5rem;
  color: #FFF;
  font-family:'poppins';
  text-decoration:none;
  display: grid;
  place-items: center;
  font-weight: 500;
  cursor: pointer;
`


const Page1 = () => {

    
    const dispatch = useDispatch();
    
    const [ showButton, setShowButton ] = useState(false)

    const showButtonTop = () => {
      if (window.scrollY >= 600) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }
  
    useEffect(() => {
      showButtonTop()
      // adding the event when scroll change background
      window.addEventListener("scroll", showButtonTop)
    })  


    return (
        <HomePage id="home">

          <Width>
            <Left>   
              <Image>
                <img src={chi} alt=""  style={{width:"100%",height:"100%"}}/>
              </Image>
              <T>
                The DAO which allows <span style={{color:'#FFBF00'}}>$CHIMP</span> holders to choose what to build and how to grow Chimp World. Stake, Trade NFTs and build. We are the future.
              </T> 
              <a 
              style={{textDecoration:"none"}}
              href='https://discord.com/invite/chimpdao' target='_blank' >
                <StyleButton>
                  Explore our Discord Community
                </StyleButton>
              </a>  
            </Left>

            <Right />

          </Width>    

          { showButton ? 
          
            <ShowButton
              type="button"
              to="home" spy={true} smooth={true}
            >
              <BsBoxArrowUp size="2rem"/>
            </ShowButton> :
            
            null
          }
            
            
        </HomePage>
    )
}

export default Page1
