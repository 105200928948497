import React from 'react'
import styled from 'styled-components';
import Tilt from 'react-parallax-tilt';
import bg from './bg.png'

const T = styled.p`
    color: #FFF;
      font-weight: 300;
    font-size: 1.05rem;
    margin: 0.7rem 0 0 0;
    font-family:'poppins';

    line-height: 1.6;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const H = styled.h1`
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
      }
`;


const Card = (props) => {
    const Box= styled(Tilt)`
        min-height: 20rem; 
        width: 20rem;   
        font-family:'poppins';

        background-image: url('${ bg }');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        padding: 4rem 2rem 3rem 2rem;
        margin: 2rem 0 0 0;
        @media only screen and (max-width: 768px) {
            min-height: 18rem; 
            width: 18rem;
            
        }
    `;

    return (
        <Box>
            <H>
                {props.heading ? props.heading :'Town Hall (Voting)'}
            </H>
           <T>
               {props.content ? props.content : 'NFT holders will get a free 8% airdrop allocation of total supply.'}
           </T>
        </Box>
    )
}

export default Card
