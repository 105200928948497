import React,{ useState } from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import styled from 'styled-components';
import logo from '../../logo.png';
import { Outlet, Link } from "react-router-dom";

const MenuItems = styled.div`
  display: grid;
  place-items: center;
  margin-top: 30%;
`

const Link1 = styled(Link)`
    display: flex;
    margin: 0 2rem;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    position: absolute;
    left: 2rem;
    top: 0.1rem;
    width: 3rem;
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 2rem 0;
    align-items: center;
`;

const Img = styled.img`
    width: 3rem;
`
const Flex = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 70%;
`;

const T = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 16px;
    margin-top: 1rem;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const H = styled(Link)`
      font-size: 1.5rem;
      font-weight: bold;
    font-family: 'Montserrat', sans-serif;
  
`;

const Menu = ({ open,setOpen,...props }) => {
  
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  return (
    <StyledMenu open={open}  aria-hidden={!isHidden} {...props}  >
      <Link1 to="/" spy={true} smooth={true}><img src={logo} alt="Logo" style={{width:"100%",height:"100%"}}/></Link1>

      <MenuItems >
        <H to="/" onClick={()=> setOpen(false)}>HOME</H> 
        <H to="/nft" onClick={()=> setOpen(false)}>NFT AUCTION</H>
        <H to="/sale" onClick={()=> setOpen(false)}>GET $CHIMP</H>
        <H to="/claim" onClick={()=> setOpen(false)}>CLAIM REFUND</H>

        <H to="/pool" onClick={()=> setOpen(false)}>STAKE</H> 
        <a
         target="_blank" href="https://cloudflare-ipfs.com/ipfs/Qmf7Eeu4vKfCm1v8z9iNLup1iozRis4gmNHPMRPncKUAxj" onClick={()=> setOpen(false)}>DOCS</a> 
        <H to="/vote" onClick={()=> setOpen(false)}>VOTE</H> 
      </MenuItems>

      <Right>
        {/* <Flex>
          <Img src={twitter} alt="" />
          <Img src={opensea} alt="" />
        </Flex> */}
        <T>ChimpDAO All Right Reserved.</T>
      </Right>

    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
