import React, { useEffect, useState } from "react";
import bg from "./bg.png";
import styled from "styled-components";
import Countdown from "react-countdown";
import Counter from "./counter.png";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import { getMyRootHash } from "../../../../../config/refundMerkle";

const StyledSalePeriod = styled.div`
  background-image: url(${bg});
  background-size: 100% 100%;
  justify-content:center;
  background-repeat: no-repeat;
  // width: 1050px;
  width:100%;
  height:20rem;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  // padding: 3rem 0 5rem 0;
  padding:20px;

  @media only screen and (max-width: 1100px) {
    width: 99%;
  }

  @media only screen and (max-width: 600px) {
    background-image: none;
  }
`;

const Count = styled.span`
  background-image: url("${Counter}");
  background-size: 100% 100%;
  width: 5rem;
  height: 5rem;
  font-size: 3.5rem;

  color: #fff;
  padding: 0 0.5rem;
`;
const T = styled.p`
  color: #fff;
  letter-spacing: 0.4rem;

  font-size: 1.3rem;
`;
const Box = styled.div`
  background-color: #312a3f;
  width: 80%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
`;
const Button = styled.button`
  background: linear-gradient(91.69deg, #c429e0 -12.45%, #662aca 100.44%);
  border: none;
  width: 100%;
  padding: 0 2rem;
  color: #fff;
  height: 4rem;
  margin-top:15px;
  text-transform:uppercase;
  // font-family:poppins;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
`;
const Container = styled.div`
  width: 80%;
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
`;
const H = styled.h1`
  font-weight: 200;
  color: #ffffff;
  font-size: 3rem;
  // margin: 2rem 0;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 2rem;
  }
`;
export const Input = styled.input`
  height: 60%;
  font-size: 2rem;
  // max-width: 50%;
  background-color: transparent;
  border: none;
  color: #fff;
  &:focus-visible {
    border: none;
  }
  &::placeholder {
    // color: #FFF;
    padding: 0 0 0 2rem;
  }
  &:focus,
  &:focus,
  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const ClaimToken = ({ auctionWriteContract, auctionReadContract }) => {
  const wallet = useWallet();
  const [claimableETHAmount, setclaimableETHAmount] = useState("0");
  const [isLoading, setLoading] = useState(false);
  const [myRootHash, setMyRootHash] = useState([]);
  const [isClaimEnabled,setClaimEnabled] = useState(false)
  
  const [hasClaimedRefund,sethasClaimedRefund] = useState(false)

  useEffect(() => {
    wallet.connect();
  }, []);

  useEffect(() => {
    if (auctionReadContract) {
      setInterval(async () => {
        await loadData();
      }, [2000]);

      loadData();
    }
  }, [auctionReadContract,wallet.account]);


  useEffect(() => {
    if (wallet.account) {
      const _myRoot = getMyRootHash(wallet.account);
      setMyRootHash(_myRoot);
    }
  }, [wallet.account]);


  const loadData = async ()=>{
    if(!auctionReadContract){
        return 
    }
    const _isClaimEnabled = await auctionReadContract.methods.isClaimRefundEnabled().call()
    if(wallet.account){
      const myClaimableAmount = await auctionReadContract.methods.bids(wallet.account).call()
      setclaimableETHAmount(myClaimableAmount);

      const hasClaimed = await auctionReadContract.methods.refundClaimed(wallet.account).call()
      sethasClaimedRefund(hasClaimed)
    }
    setClaimEnabled(_isClaimEnabled)
    

  }

  const handleClaim = async ()=>{

    setLoading(true)

    try{
      await auctionWriteContract.methods.claimRefund(myRootHash).send({
        from:wallet.account
      })
    }catch(error){
    }
    setLoading(false)

  }
  const renderParticipateButton = () => {
    let text = "";
    let onClick = null;

    if(!isClaimEnabled){
      text = "Claim Not Enabled"

    }else if (wallet.account) {
     
      if(Number(claimableETHAmount)>0 && !hasClaimedRefund){
        text = "Claim Your ETH"
        onClick = handleClaim
      }else{
        text = "Nothing to Claim"

      }
    } else {
      text = "Connect Wallet";
      onClick = wallet.connect;
    }
    return (
      <Button
        disabled={isLoading}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {isLoading ? "Loading..." : text}
      </Button>
    );
  };

  const renderSubHeadingText =()=>{
    let text = "";

    if(hasClaimedRefund){
      text = `ALREADY ${'\n'} CLAIMED `
    }else{
      text = `CLAIM YOUR ${Number(claimableETHAmount)>0?Web3.utils.fromWei(claimableETHAmount.toString()):""} ETH`
    }

    return text
  }
  return (
    <StyledSalePeriod>
      <div style={{padding:20,alignContent:"center",alignItems:"center"}}> 

      <H style={{textAlign:"center"}}>
      

{renderSubHeadingText()}
        </H>
    
   
     

    {renderParticipateButton()}
      </div>
    </StyledSalePeriod>
  );
};

export default ClaimToken;
