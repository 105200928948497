import React, { useEffect, useState } from "react";
import styled from "styled-components";
import bg from "./card.png";
import { Button } from "../../../../components/Navbar/Navbar";
import Bid from "./components/Bid";
import { getAllBidders } from "../../../../apis/auction";
import MyBid from "./components/MyBid";

import Countdown from "react-countdown";
import Counter from "./counter.png";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import config from "../../../../config"
import CHIMP_NFT from "../../../../config/abi/CHIMP_NFT.json"
import { getMyRootHash } from "../../../../config/nftClaimMerkle"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Sec = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;

  @media only screen and (max-width: 768px) {
    padding: 2rem 0;
    background-size: auto 100%;
  }
`;

const Box = styled.div`
  background-color: #312a3f;
  width: 50%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1100px;
  padding: 4rem 0;

  @media only screen and (max-width: 1400px) {
    width: 96%;
  }
  @media only screen and (max-width: 1000px) {
    width: 99%;
  }
  @media only screen and (max-width: 768px) {
    background-image: none;
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
  @media only screen and (max-width: 600px) {
    width: 99%;
    padding: 2rem 0 3rem 0;
    border-radius: 1rem;
  }
`;
const Count = styled.span`
  background-image: url("${Counter}");
  background-size: 100% 100%;
  width: 5rem;
  height: 5rem;
  font-size: 3.5rem;

  color: #fff;
  padding: 0 0.5rem;
`;
const T = styled.p`
  color: #fff;
  letter-spacing: 0.06rem;
  //font-weight: lighter;
  font-size: 1.5rem;
  // font-family: 'Poppins', sans-serif;
`;

const H = styled.h1`
  color: #fff;
  letter-spacing: 0.06rem;
  // font-weight: lighter;
  font-size: 2rem;
  margin: 2rem 0 1rem 0;
  // font-family: 'Poppins', sans-serif;
  @media only screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const Align = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 30rem;
  margin: 0 0 2.5rem 0;

  @media only screen and (max-width: 630px) {
    flex-direction: column;
    width: 95%;
  }
`;

export const Input = styled.input`
  height: 60%;
  font-size: 2rem;
  max-width: 100%;
  background-color: transparent;
  border: none;
  texttransform: uppercase;
  color: #fff;
  &:focus-visible {
    border: none;
  }
  &::placeholder {
    // color: #FFF;
    // padding: 0 0 0 2rem;
  }
  &:focus,
  &:focus,
  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  @media only screen and (max-width: 630px) {
    font-size: 1rem;
  }
`;

const Page7 = ({
  auctionReadContract,
  auctionWriteContract,
  onBidsFetched,
}) => {
  const wallet = useWallet();
  const [participationState, setParticipationState] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [ethInputAmount, setEthInputAmount] = useState();
  const [myRank, setMyRank] = useState("-");
  const [smallestBidAmount, setSmallestBidAmount] = useState();
  const [biggestBidAmount, setBiggestBidAmount] = useState();
  const [nftClaimMerkle, setNFTClaimMerkle] = useState([]);

  const [isClaimRefundEnabled, setClaimRefundEnabled] = useState(true);
  
  const [hasClaimedNFT, sethasClaimedNFT] = useState(false);

  const [myContribution, setMyContribution] = useState(0);

  const [minimumBiddingAmount, setMinimumBiddingAmount] = useState(0);


  useEffect(() => {
    if (wallet.account) {
      const root = getMyRootHash(wallet.account);
      setNFTClaimMerkle(root)
    }
  },[wallet.account])
  const handleParticipate = async () => {
    if (!Number(ethInputAmount) || Number(ethInputAmount) == 0) {
      alert("Please Enter Valid ETH amount");
      return;
    }

    if (Number(Web3.utils.toWei(ethInputAmount)) < minimumBiddingAmount) {
      alert("Please Enter Minimum Bidding amount");
      return;
    }

    setLoading(true);

    try {
      await auctionWriteContract.methods.placeBid().send({
        from: wallet.account,
        value: Web3.utils.toWei(ethInputAmount),
      });
    } catch (err) {
      console.error("handleParticipate", err);
    }
    setLoading(false);
  };

  const onClaimNFT = async () => {
    setLoading(true);

    try {

      const nftContract = getNFTContract()
      await nftContract.methods.claimNFT(nftClaimMerkle).send({ from: wallet.account });
      
    } catch (err) {
      console.error("handleParticipate", err);
    }
    setLoading(false);

  };




  const renderButton = () => {
    let onClick = null;
    let text = "";
    if (wallet.account) {
      if (participationState == 0) {
        // disabled
        text = "Bidding not enabled";
      } else if (participationState == 1 && !isClaimRefundEnabled) {
        // enabled
        text = "Place Bid";
        onClick = handleParticipate;
      } else if (isClaimRefundEnabled) {
        // enabled
        let { title, onClickFunction } = renderClaimButton();
        text = title;
        onClick = onClickFunction;
      } else if (participationState == 2) {
        // finished
        text = "Auction Finished";
      }
    } else {
      text = "Connect Wallet";
      onClick = wallet.connect;
    }
    return (
      <Button
        style={{ marginTop: 20 }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {text.toUpperCase()}
      </Button>
    );
  };

  const renderClaimButton = () => {
    let title = "Claim NFT";
    let onClickFunction = onClaimNFT;
    if (nftClaimMerkle.length == 0) {
      onClickFunction = null
      title = "Nothing to Claim"
    } else if (hasClaimedNFT) {
      
      onClickFunction = null
      title = "Already Claimed NFT"
    }

    return { title, onClickFunction };
  };

  const walletStatus = () => {
    wallet.account ? (
      <T>CONNECTED: {wallet.account.slice(0, 10)}...</T>
    ) : (
      <T>CONNECT WALLET</T>
    );
  };

  const renderHeaderText = () => {
    let text;
    if (participationState == 0) {
      text = "Coming Soon";
    } else if (participationState == 1) {
      text = "Auction Opened";
    } else if (participationState == 2) {
      text = "Auction Finished ";
    }
    return text;
  };


  const getNFTContract = () => {
    const web3 = new Web3(wallet.ethereum);

    return new web3.eth.Contract(CHIMP_NFT, config.CHIMP_NFT)
  }
  const loadGlobalData = async () => {
    const isOpen = await auctionReadContract.methods.isAuctionEnabled().call();
    const _isClaimRefundEnabled = await auctionReadContract.methods
      .isClaimRefundEnabled()
      .call();
    
    
    setClaimRefundEnabled(_isClaimRefundEnabled);


    const nftContract = getNFTContract()
    // const sethasClaimedNFT
 
    const state = isOpen ? 1 : 0;

    const _minimumBiddingAmount = await auctionReadContract.methods
      .minBidAmount()
      .call();

    setMinimumBiddingAmount(_minimumBiddingAmount);
    setParticipationState(state);

    if (wallet.account) {
      const _minimumBiddingAmount = await auctionReadContract.methods
        .bids(wallet.account)
        .call();

      setMyContribution(_minimumBiddingAmount);
      const hasClaimed = await nftContract.methods.nftClaims(wallet.account).call()
      sethasClaimedNFT(hasClaimed)
    }
  };

  const loadGraphData = async () => {
    const bidders = await getAllBidders();
    onBidsFetched(bidders);
    if (wallet.account) {
      const _myRank = bidders.findIndex((x) => {
        return x.id.toLowerCase() === wallet.account.toLowerCase();
      });

      if (_myRank != -1) {
        setMyRank(_myRank + 1);
      }
    }
    if (bidders.length > 0) {
      const _smallestBid = bidders[bidders.length - 1].totalBidAmount;
      const _biggestBid = bidders[0].totalBidAmount;
      setBiggestBidAmount(_biggestBid);
      setSmallestBidAmount(_smallestBid);
    }
  };
  useEffect(() => {
    if (auctionReadContract) {
      setInterval(async () => {
        await loadGlobalData();
      }, [2000]);
      loadGlobalData();
    }
  }, [auctionReadContract, auctionWriteContract]);

  useEffect(() => {
    setInterval(async () => {
      await loadGraphData();
    }, [2000]);
    loadGraphData();
  }, [wallet.account]);

  const renderClaimMode = () => {
    return (
      <>
        <T
          style={{
            marginTop: 10,
            fontSize: 12,
            fontFamily: "poppins",
            marginLeft: 20,
            marginRight: 20,
            textAlign: "center",
            justifyContent: "center",
          }}
        >
         Thank you for participating in ChimpDAO's Genesis NFT Auction. <br/><br/>
To the 300 Genesis Chimps, congratulations, you can now proceed to mint your NFT here.<br/>
          <br /> To those who did not make it into the list of 300 Genesis Chimps,<br/> you are not left behind - we will be launching our $CHIMP token sale very soon.
        </T>
        {!isLoading ? (
          renderButton()
        ) : (
          <Button style={{ marginTop: 10 }}>Loading...</Button>
        )}
      </>
    );
  };

  const renderParticipationMode = () => {
    return (
      <>
        {participationState != 1 ? (
          <Align>
            <T>START DATE</T>
            <T>2022-04-25</T>
          </Align>
        ) : null}

        {participationState == 1 ? (
          <Box>
            <Input
              type="number"
              min="0"
              value={ethInputAmount}
              placeholder="ENTER ETH AMOUNT"
              onChange={(e) => {
                setEthInputAmount(e.target.value);
              }}
            />
          </Box>
        ) : null}
        <T style={{ marginTop: 10, fontSize: 14, fontFamily: "poppins" }}>
          Minimum Bid: {Web3.utils.fromWei(minimumBiddingAmount.toString())} ETH
        </T>

        <T
          style={{
            marginTop: 10,
            fontSize: 12,
            fontFamily: "poppins",
            marginLeft: 20,
            marginRight: 20,
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          Please take note that your bids are cumulative.
          <br /> If you have already placed a bid for 0.5 ETH and choose to up
          your bid to 1.2 ETH,
          <br /> your second bid should be 0.7 ETH.
        </T>
        {!isLoading ? (
          renderButton()
        ) : (
          <Button style={{ marginTop: 10 }}>Loading...</Button>
        )}
      </>
    );
  };
  return (
    <Sec>
      <Center>
        <Bid
          smallestBidAmount={smallestBidAmount}
          biggestBidAmount={biggestBidAmount}
        />
        <MyBid myRank={myRank} myContribution={myContribution} />

        {isClaimRefundEnabled ? (
          <H style={{ textTransform: "uppercase" }}>Claim NFT</H>
        ) : (
          <H style={{ textTransform: "uppercase" }}>{renderHeaderText()}</H>
        )}
        {wallet.account ? (
          <H style={{ fontSize: 12, textTransform: "uppercase" }}>
            Connected Wallet:
            <br />
            {wallet.account ? `${isMobile?`${wallet.account.substring(0,8)}....${wallet.account.substring(wallet.account.length-8,wallet.account.length)}`:wallet.account}` : ""}
          </H>
        ) : null}

        {/* <Countdown date={Date.now() + 900000} renderer={renderer} /> */}

        <T>{walletStatus()}</T>
        {isClaimRefundEnabled ? renderClaimMode() : renderParticipationMode()}
      </Center>
    </Sec>
  );
};

export default Page7;
