import React from 'react'
import styled from 'styled-components';
import Card from './components/Card';
import i1 from './1.png'
import i2 from './2.png'
import i3 from './3.png'
import i4 from './4.png'
import Web3 from 'web3';
import moment from "moment"

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction; column;
    justify-content: center;
    background-color: #000;

    @media only screen and (max-width: 768px) {
        min-height: 100vh;
        width: 100%;
        padding: 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1300px;
      display: flex;
      flex-direction: column;
  
      align-items: center;
      @media only screen and (max-width: 1300px) {
        width: 95%;
      }



`


const Ht = styled.h1`
   
    font-size: 3rem;
    font-weight: bold;
    margin: 10rem 0 5rem 0;

          color: #FFFFFF;
          
          
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.5rem;
        font-weight: bold;
      }
`;

const Resposive = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #8c26aa;


      @media only screen and (max-width: 768px) {
        display: grid;
         
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
       
        // align-items: center;
        // justify-content: space-between;
        place-items: stretch center;
      }

      
`
const T = styled.p`
    color: #FFF;

    font-size: 1.2rem;
 
    line-height: 1.4;
    text-align: center;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const TableItem = (props) => {
    const Box= styled.div`
        height: 1rem; 
        width: 12rem;   
        background-image: url('${props.img ? props.img : ''}');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        font-family:poppins;
        align-items: center;
        justify-content: center;
        padding: ${props.special ? '4.2rem 2rem 2rem 2rem ' : '2rem' };
        margin: 1rem 0;

   
    `;

    return (
        <Box>
           <T style={{fontFamily:"poppins",textTransform:"uppercase"}}>
               {props.heading ? props.heading : ""}
           </T>
        </Box>
    )
}

const Page6a = ({bids=[]}) => {
    return (
        <Sec>
            <Width>
                <Ht>TOP 10 BIDS</Ht>
                <Resposive>
                    <Card img={i1} heading="POSITION" />
                    <Card img={i2} heading="USER ADDRESS" />
                    <Card img={i3} heading="BID AMOUNT" special/>
                    <Card img={i4} heading="BID TIME" />
                </Resposive>

                {bids.slice(0,10).map((item,index)=>{
                    return <Resposive>
                    <TableItem  heading={index+1} />
                    <TableItem  heading={`${item.id.substring(0,5)}......${item.id.substring(item.id.length-5,item.id.length)}`} />
                    <TableItem  heading={`${Web3.utils.fromWei(item.totalBidAmount)} ETH`} />
                    <TableItem  heading={moment(item.bids[0].timestamp*1000).format("DD-MMM hh:mm:ss a")} />

                </Resposive>
                })}
            </Width>
        </Sec>
    )
}

export default Page6a
