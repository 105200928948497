import React,{ useState, useEffect} from 'react'
import styled from 'styled-components'
 import bg from './bg.png'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from "react-redux";
import { BsBoxArrowUp } from 'react-icons/bs';
import { Link, animateScroll as scroll } from "react-scroll"; 

const HomePage = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #000;
  padding: 5rem 0 5rem 0;

@media only screen and (max-width: 768px) {
    min-height: 100vh
    width: 100%;
    padding: 0rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
}`

const Width = styled.div`
      width: 1300px;
      display: flex;
      flex-direction: column;
        align-items: center;
        justify-content: center;
      @media only screen and (max-width: 1700px) {
        width: 90%;
      }
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const T = styled.p`
    color: #FFFFFF ;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: right;
    margin: 1.2rem 0 0.4rem 0;
    letter-spacing: 0.15rem;
    text-align: center;
   // max-width: 900px;

  //  font-family: 'Poppins', sans-serif;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 0.75rem;

      }
`;

const H = styled.p`
  
  
      font-weight: 200;
        color: #FFFFFF;
        font-size: 2rem;
        margin: 0 0 0.75rem 0;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.8rem;
      }
`;

const Hb = styled.p`
    

      font-weight: 400;
        color: #FFFFFF;
        font-size: 4rem;
        text-align: center;
    @media only screen and (max-width: 768px) {
      
        font-size: 1.2rem;
      }
`;

const ShowButton = styled(Link)`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background: linear-gradient(97.53deg, #C429E0 18.8%, #812BE1 89.56%); 
    color: #FFF ;
    font-weight: bold;
    cursor: pointer;
    position: fixed;
    height: 4rem;
    width: 3rem;
    border-radius: 0.5rem;
    transition:  0.5s linear;
    right: 3rem ;
    bottom: 10rem;
    display: grid;
    place-items: center;
    z-index: 7;
    @media only screen and (max-width: 768px) {
      right: 2rem ;
    bottom: 5rem;
    }
    
`

const Page1 = () => {

    
    const dispatch = useDispatch();
    
    const [ showButton, setShowButton ] = useState(false)

    const showButtonTop = () => {
      if (window.scrollY >= 600) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }
  
    useEffect(() => {
      showButtonTop()
      // adding the event when scroll change background
      window.addEventListener("scroll", showButtonTop)
    })  


    return (
        <HomePage id="home">

          <Width>
           
           <div>
              <H style={{textAlign:'center'}}>ChimpDAO</H>
              <Hb style={{margin:'0.4rem 0 0 0'}}>PROJECT VOTING</Hb>
              <T>
                TRULY FUNCTION AS A COMMUNITY-RUN INCUBATOR WITHOUT
                ANY BORDERS OR BARRIERS TO PARTICIPATION
              </T>
            </div>

          

          </Width>    

          { showButton ? 
          
            <ShowButton
              type="button"
              to="home" spy={true} smooth={true}
            >
              <BsBoxArrowUp size="2rem"/>
            </ShowButton> :
            
            null
          }
            
            
        </HomePage>
    )
}

export default Page1
