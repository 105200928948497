import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./Bg.css";
import bg from "./bg.png";
import wide from "./wide.png";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { BsBoxArrowUp } from "react-icons/bs";
import { Link, animateScroll as scroll } from "react-scroll";
import { Button } from "../../../../components/Navbar/Navbar";
import SalePeriod from "./components/SalePeriod";
import ClaimToken from "./components/ClaimToken";

import Web3 from "web3";
import config from "../../../../config";
import AUCTION_ABI from "../../../../config/abi/AUCTION.json";
import { useWallet } from "use-wallet";


const HomePage = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #000;
  padding: 5rem 0 5rem 0;
  background-attachment: fixed;

  @media only screen and (max-width: 768px) {
    min-height: 50rem;
    width: 100%;
    padding: 0rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
  }
`;

const Width = styled.div`
  width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1700px) {
    width: 90%;
  }
  @media only screen and (max-width: 1400px) {
    width: 95%;
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

const T = styled.p`
  color: #ffffff;
  font-weight: 250;
  font-size: 1.2rem;
  text-align: right;
  margin: 0 0 0.4rem 0;
  letter-spacing: 0.05rem;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;

const H = styled.p`
  font-weight: 200;
  color: #ffffff;
  font-size: 2rem;

  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;

const Hb = styled.p`
  font-weight: 400;
  color: #ffffff;
  font-size: 4rem;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ShowButton = styled(Link)`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  background: linear-gradient(97.53deg, #c429e0 18.8%, #812be1 89.56%);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  position: fixed;
  height: 4rem;
  width: 3rem;
  border-radius: 0.5rem;
  transition: 0.5s linear;
  right: 3rem;
  bottom: 10rem;
  display: grid;
  place-items: center;
  z-index: 7;
  @media only screen and (max-width: 768px) {
    right: 2rem;
    bottom: 5rem;
  }
`;

const Left = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 85%;
    //height: 20rem;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  // width: 998px;
  padding: 2.5rem 4rem;
  justify-content: space-between;
  background-image: url(${wide});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 3rem 0;
  @media only screen and (max-width: 998px) {
    width: 100%;
    padding: 1.5rem 2rem;
  }
  @media only screen and (max-width: 600px) {
    background-image: none;
    flex-direction: column;
    margin: 0.5rem 0;
  }
`;

const Hxs = styled.p`
  color: #ffffff;
  font-size: 1.7rem;
  font-weight: 450;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 600px) {
    //margin-bottom: 2rem;
    text-align: center;
  }
`;

const Page1 = () => {
  const dispatch = useDispatch();

  const [showButton, setShowButton] = useState(false);

  const wallet = useWallet();

  const [auctionReadContract, setAuctionReadContract] = useState();
  const [auctionWriteContract, setAuctionWriteContract] = useState();


  const showButtonTop = () => {
    if (window.scrollY >= 600) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    showButtonTop();
    // adding the event when scroll change background
    window.addEventListener("scroll", showButtonTop);
    loadReadContract();
  }, []);

  useEffect(() => {
    if (wallet.account) {
      loadWriteContract();
    }
  }, [wallet.account]);

  const loadReadContract = async () => {
    const web3 = new Web3(config.rpc);
    const contract = new web3.eth.Contract(
      AUCTION_ABI,
      config.AUCTION_CONTRACT
    );
    setAuctionReadContract(contract);

  };

  const loadWriteContract = () => {
    const web3 = new Web3(wallet.ethereum);
    const contract = new web3.eth.Contract(
      AUCTION_ABI,
      config.AUCTION_CONTRACT
    );
    setAuctionWriteContract(contract);
  };

  const renderPanel = () => {
    return  <ClaimToken
    auctionWriteContract={auctionWriteContract}
    auctionReadContract={auctionReadContract}
  />
  };
  return (
    <HomePage id="home" >
      <Width style={{marginTop:20,marginBottom:20}}>
        <Left style={{marginBottom:20}}>
          
          <Hb style={{ margin: "0.4rem 0 0 0" ,marginTop:20}}> AUCTION REFUND</Hb>
          <Hxs style={{textAlign:"center",fontFamily:"poppins"}}>ONLY APPLIES TO USERS WHO SUBMITTED BIDS BUT WERE NOT SUFFICIENT TO BE THE TOP 300 HIGHEST BIDS.
</Hxs>

        </Left>

        

        {renderPanel()}
      </Width>

     
    </HomePage>
  );
};

export default Page1;
