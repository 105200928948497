import React, { useEffect, useState } from "react";
import bg from "./bg.png";
import styled from "styled-components";
import Countdown from "react-countdown";
import Counter from "./counter.png";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import { getMyRootHash } from "../../../../../config/merkle";

const StyledSalePeriod = styled.div`
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1050px;
  min-height: 55rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0 5rem 0;

  @media only screen and (max-width: 1100px) {
    width: 99%;
  }

  @media only screen and (max-width: 600px) {
    background-image: none;
    padding: 1rem 0 5rem 0;
  }
`;

const Count = styled.span`
  background-image: url("${Counter}");
  background-size: 100% 100%;
  width: 5rem;
  height: 5rem;
  font-size: 3.5rem;

  color: #fff;
  padding: 0 0.5rem;
`;
const T = styled.p`
  color: #fff;
  letter-spacing: 0.4rem;

  font-size: 1.3rem;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;
const Box = styled.div`
  background-color: #312a3f;
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
`;
const Button = styled.button`
  background: linear-gradient(91.69deg, #c429e0 -12.45%, #662aca 100.44%);
  border: none;
  width: 80%;
  padding: 0 0.5rem;
  color: #fff;
  height: 3.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;

  // font-family: 'Poppins', sans-serif;
  
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    
    font-size: .75rem;
  }
`;
const Container = styled.div`
  width: 80%;
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    width: 98%;
    // display: flex;
    // aling-items: center;
    // justify-content: space-between;
  }
`;
const H = styled.h1`
  font-weight: 200;
  color: #ffffff;
  font-size: 3rem;
  // margin: 2rem 0;
  font-weight: 510;
  // font-family: 'Poppins', sans-serif;
  @media only screen and (max-width: 768px) {
    
    font-size: 2rem;
  }
`;
export const Input = styled.input`
  height: 50%;
  font-size: 1.7rem;
  max-width: 100%;
  width:100%;
  background-color: transparent;
  border: none;
  color: #fff;
  // font-family: 'Poppins', sans-serif;
  &:focus-visible {
    border: none;
  }
  &::placeholder {
    // color: #FFF;
   // padding: 0 0 0 1rem;
  }
  &:focus,
  &:focus,
  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    &::placeholder {
      // color: #FFF;
      padding: 0 0 0 1rem;
      font-size: 1rem;
     // width: 20rem !important;
    }
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

 

`;


const SalePeriod = ({ presaleWriteContract, presaleReadContract }) => {
  const wallet = useWallet();
  const [ethInputAmount, setETHInputAmount] = useState();
  const [chimpPrice, setChimpPrice] = useState("0");
  const [chimpInputAmount, setChimpInputAmount] = useState();
  const [participationAmount, setParticipationAmount] = useState("0");
  const [claimableChimpAmount, setClaimableChimpAmount] = useState("0");
  const [isPresaleOpened, setIsPresaleOpened] = useState(false);
  const [maxPurchaseAmount, setMaxPurchaseAmount] = useState("0");
  const [isLoading, setLoading] = useState(false);
  const [myRootHash, setMyRootHash] = useState([]);
  useEffect(() => {
    wallet.connect();
  }, []);

  useEffect(() => {
    if (presaleReadContract) {
      setInterval(async () => {
        await loadPresaleData();
      }, [2000]);

      loadPresaleData();
    }
  }, [presaleReadContract,wallet.account]);

  const loadPresaleData = async () => {
    const _chimpPrice = await presaleReadContract.methods.chimpPrice().call();
    setChimpPrice(_chimpPrice);

    const isOpen = await presaleReadContract.methods.isDepositEnabled().call();
    setIsPresaleOpened(isOpen);

    const _maxBuyAmount = await presaleReadContract.methods
      .maxPurchaseAmount()
      .call();
    setMaxPurchaseAmount(_maxBuyAmount);
    if (wallet.account) {
      const _participationAmount = await presaleReadContract.methods
        .participations(wallet.account)
        .call();

      setParticipationAmount(_participationAmount);

      const _claimableChimpAmount = await presaleReadContract.methods
        .tokensToBeClaimed(wallet.account)
        .call();

      setClaimableChimpAmount(_claimableChimpAmount);
    }
  };
  const handleDeposit = async () => {
    if (!ethInputAmount || Number(ethInputAmount) == 0) {
      alert("Please Enter Valid ETH Amount");
      return;
    }

    if (
      Number(Web3.utils.fromWei(maxPurchaseAmount)) < Number(chimpInputAmount)
    ) {
      alert("Can't Buy More Than Max Limit!");
      return;
    }

    setLoading(true);

    try {
      await presaleWriteContract.methods.deposit(myRootHash).send({
        from: wallet.account,
        value: Web3.utils.toWei(ethInputAmount.toString()),
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (wallet.account) {
      const _myRoot = getMyRootHash(wallet.account);
      setMyRootHash(_myRoot);
    }
  }, [wallet.account]);

  const renderParticipateButton = () => {
    let text = "";
    let onClick = null;
    if (wallet.account) {
      // if(is)
      if (myRootHash.length != 0) {
        if (isPresaleOpened) {
          text = "Deposit";
          onClick = handleDeposit;
        } else {
          text = "Deposit not opened";
        }
      } else {
        text = "Not Whitelisted";
      }
    } else {
      text = "Connect Wallet";
      onClick = wallet.connect;
    }
    return (
      <Button
        disabled={isLoading}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        
      >
        {isLoading ? "Loading..." : text.toLocaleLowerCase()}
      </Button>
    );
  };
  return (
    <StyledSalePeriod>
      <T>SALE PERIOD ENDS</T>
      <H>{isPresaleOpened?"PARTICIPATE":"COMING SOON"}</H>
      <Container style={{ }}>
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            margin: "0 0 0.75rem 0",
            // fontFamily: 'Poppins',
          }}
        >
       I WANT TO DEPOSIT
        </p>
        <Box >
          <Input
            type="number"
            min="0"
            placeholder="ENTER ETH AMOUNT"
            // style={{width:'18rem'}}
            value={ethInputAmount}
            onChange={(e) => {
              setETHInputAmount(e.target.value);

              const ethToChimp =
                Number(e.target.value) / (Number(chimpPrice) / 1e18);
              setChimpInputAmount(ethToChimp);
            }}
          />
        </Box>
      </Container>
      <Container >
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            margin: "0 0 0.75rem 0",
            // fontFamily: 'Poppins',
          }}
        >
          YOU WILL GET (CHIMP)
        </p>
        <Box >
          <Input
            type="number"
            min="0"
            // style={{width:'18rem'}}
            placeholder="0 CHIMP"
            value={chimpInputAmount}
            onChange={(e) => {
              setChimpInputAmount(e.target.value);

              const chimpToEth =
                Number(e.target.value) * (Number(chimpPrice) / 1e18);
              setETHInputAmount(chimpToEth);
            }}
          />
        </Box>
      </Container>

      <Container
        style={{
          // margin: "0 0 3rem 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            // margin: "0 0 0.05rem 0",
            // fontFamily: 'Poppins',
          }}
        >
          CHIMP PRICE
        </p>
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            // margin: "0 0 0.05rem 0",
            //fontFamily: 'Poppins',
            textAlign: "right",
          }}
        >
          {Web3.utils.fromWei(chimpPrice)} ETH
        </p>
      </Container>

      <Container
        style={{
          // margin: "0 0 3rem 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            textTransform:"uppercase"
            // margin: "0 0 0.05rem 0",
            // fontFamily: 'Poppins',
          }}
        >
          Max Buy Limit
        </p>
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            // margin: "0 0 0.05rem 0",
            textTransform:"uppercase",

            textAlign: "right",
          }}
        >
          {Web3.utils.fromWei(maxPurchaseAmount)} CHIMP
        </p>
      </Container>

      <Container
        style={{
          // margin: "0 0 3rem 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            // margin: "0 0 0.05rem 0",
            // fontFamily: 'Poppins',
            textTransform:"uppercase"

          }}
        >
          Your Participation Amount
        </p>
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            // margin: "0 0 0.05rem 0",
            textAlign: "right",
            textTransform:"uppercase"

          }}
        >
          {Web3.utils.fromWei(participationAmount)} ETH
        </p>
      </Container>

      <Container
        style={{
          // margin: "0 0 3rem 0",
          display: "flex",
          marginBottom:10,
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            textTransform:"uppercase"

            // margin: "0 0 0.75rem 0",
            // fontFamily: 'Poppins',
          }}
        >
          Your Claimable CHIMP
        </p>
        <p
          style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "1.3rem",
            letterSpacing: "0.1rem",
            // margin: "0 0 0.75rem 0",
            textAlign: "right",
            textTransform:"uppercase"

          }}
        >
          {Web3.utils.fromWei(claimableChimpAmount)} CHIMP
        </p>
      </Container>
      {renderParticipateButton()}
    </StyledSalePeriod>
  );
};

export default SalePeriod;
