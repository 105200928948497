import React from 'react'
import styled from 'styled-components'
import bg from './card.png'

const StylePool = styled.div`
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 1000px;
    padding: 5rem 5rem 6rem 5rem;
    display: grid;
    place-items: center;

    @media only screen and (max-width: 1000px) {
        width: 100%;
      }

      @media only screen and (max-width: 768px) {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      }

`

const H = styled.p`
  

      font-weight: 200;
        color: #FFFFFF;
        font-size: 1.75rem;
        // text-align: center;
        margin: 0 0 2rem 0;
        
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
    
        margin: 1rem 0 0 0;
      }
`;

const Lower = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 700px;
    @media only screen and (max-width: 900px) {
        width: 95%;
       
      }
      @media only screen and (max-width: 600px) {
        width: 95%;
       flex-direction: column;
      }
`


function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const PoolInfo = ({apr,tvl}) => {
  return (
    <StylePool>
        <H>POOL INFO</H>
        <Lower>
            <div style={{margin:'2rem 0 0 0'}}>
                <p style={{color:'rgba(255,255,255,0.8)',fontSize:'1.3rem',letterSpacing:'0.1rem',margin:'0 0 0.75rem 0',
                // fontFamily: 'Poppins',
                 }}>
                    $CHIMP STAKED</p>
                <H>{numberWithCommas(Number(tvl).toFixed(2))}</H>
            </div>

              

            <div style={{margin:'2rem 0 0 0',marginLeft:10}}>
                <p style={{color:'rgba(255,255,255,0.8)',fontSize:'1.3rem',letterSpacing:'0.1rem',margin:'0 0 0.75rem 0',
                // fontFamily: 'Poppins',
                 }}>
                     EST APR</p>
                <H>{apr}%</H>
            </div>



            {/* <div style={{margin:'2rem 0 0 0'}}>
                <p style={{color:'rgba(255,255,255,0.8)',fontSize:'1.3rem',letterSpacing:'0.1rem',margin:'0 0 0.75rem 0',
                // fontFamily: 'Poppins', 
                }}>
                    EST APR</p>
                <H>{apr}%</H>
            </div> */}
        </Lower>
    </StylePool>
  )
}

export default PoolInfo