import React from "react";
import styled from "styled-components";
import Web3 from "web3";
import eth from "./eth.svg";

const Hb = styled.p`
  font-weight: 400;
  color: #ffffff;
  font-size: 2.5rem;

  margin: 0.5rem 0 0 0.5rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const T = styled.p`
  color: #fff;
  letter-spacing: 0.06rem;
  font-weight: lighter;
`;

const Bid = ({ smallestBidAmount, biggestBidAmount }) => {
  const StyledBid = styled.div`
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media only screen and (max-width: 800px) {
      width: 80%;
    }
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
    @media only screen and (max-width: 630px) {
      flex-direction: column;
    }
  `;

  return (
    <StyledBid>
      <div style={{ margin: "1rem 0 0 0",
              visibility: biggestBidAmount ? "visible" : "hidden",
            }}>
        <T>HIGHEST BID POSITION</T>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0.5rem 0",
            justifyContent: "center",
          }}
        >
          <img src={eth} alt="" style={{ height: "2.5rem" }} />
          <Hb>            {biggestBidAmount ? Web3.utils.fromWei(biggestBidAmount) : "-"} {" "}ETH
</Hb>
        </div>
      </div>

      <div
        style={{
          margin: "1rem 0 0 0",

          visibility: smallestBidAmount ? "visible" : "hidden",
        }}
      >
        <T style={{textAlign:'right'}}>LOWEST BID POSITION</T>
        <div
          style={{
            display: "flex",

            alignItems: "center",
            margin: "0.5rem 0",
            justifyContent: "center",
          }}
        >
          <img src={eth} alt="" style={{ height: "2.5rem" }} />
          <Hb>
            {smallestBidAmount ? Web3.utils.fromWei(smallestBidAmount) : "-"}{" "}
            ETH
          </Hb>
        </div>
      </div>
    </StyledBid>
  );
};

export default Bid;
