import React from 'react'
import Page1 from './components/Page1/Page1'
import Balance from './components/Balance/Balance'

const Pools = () => {
  return (
    <>
      <Page1 />
    
    </>
  )
}

export default Pools