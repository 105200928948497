


 
const TESTNETCONFIG = {
    rpc:"https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    chainId:4,
    THEGRAPH:"https://api.thegraph.com/subgraphs/name/shahzeb8285/chimpdao",
    AUCTION_CONTRACT:"0x4440aFa050185A27C85900ACAC2AED45f387fca9",
    PRESALE_CONTRACT:"0xA30e596eDbdc6E80A8E617673bcEFE2822fEaf5A",
    CHIMP_CONTRACT: "0x83De5C6F3363790319df2d20E3f4f8747b7E5019",
    CHIMP_NFT:"0xA22315F48A9b3389e7A8EF0B9bffe51648460CE1",

    MASTERCHEF_CONTRACT:"0x588D4e7F2eeaFC691D0825E68F9F371B8582fBA6"
}


export default {
    ...TESTNETCONFIG
}