import React from 'react'
import styled from 'styled-components'
import bg from './bg.png'
import cen from './center.png'
import data from './max.png'
import { H, T } from '../Page2/Page2'
import a from './a.png'
import b from './b.png'
import c from './c.png'

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
  
    align-items: center;
    justify-content: center;
    padding: 3rem  0;
    background-color:  #000000;
    background-repeat: no-repeat;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-position:  center center;
  
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 1rem 0 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1500px;
      display: flex;
      flex-direction: column;
    justify-content: center; 
      align-items: center;
      @media only screen and (max-width: 1500px) {
        width: 100%;
      }
   
   
`
const Center = styled.div`
      width: 50rem;
      height: 32rem;

      @media only screen and (max-width: 800px) {
        width: 90%;
        height: 25rem;
      }

      @media only screen and (max-width: 500px) {
        width: 99%;
        height: 18rem;
      }


      @media only screen and (max-width: 430px) {
        width: 99%;
        height: 16rem;
      }
`
const Flex = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 3rem 0 1rem 0 ;
      width: 768px;
    @media only screen and (max-width: 790px) {
        flex-direction: column;
        justify-content: center;
        width: 95%;
      }
`

const Img = styled.div`
      width: 12rem;
      height: 7rem;
`
const ImgC = styled.div`
      width: 12rem;
      height: 7rem;
      @media only screen and (max-width: 790px) {
        margin: 2rem 0;
      }
`

const Page5 = () => {
  
    return (
        <Sec>
            <Width>
                <H style={{margin:'0 0 4rem 0'}} >$CHIMP TOKENOMICS</H>
                <Center>
                    <img src={data} alt='' style={{width:'100%',height:'100%'}} />
                </Center>
                <T style={{margin:'4rem 0 0 0',fontSize:'4rem',fontWeight:'bold'}}>STRATEGIC PARTNERS</T>
                <Flex>
                  <Img><img src={a} alt='' style={{width:'100%',height:'100%'}} /> </Img>
                  <ImgC><img src={b} alt='' style={{width:'100%',height:'100%'}} /> </ImgC>
                  <Img><img src={c} alt='' style={{width:'100%',height:'100%'}} /> </Img>
                </Flex>
            </Width>
        </Sec>
    )
}

export default Page5
