import React from 'react'
import styled from 'styled-components'
import bg from './bg3.png'
import Card from './components/Card';
import WideCard from './components/WideCard';

const Sec = styled.section`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    background-color: #000;
    width: 100%;
    background-image: url('${bg}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  
    padding: 5rem 0;

    @media only screen and (max-width: 1500px) {
        background-size: auto 100%;
        background-position: center center;
    }
    
    
    @media only screen and (max-width: 768px) {
        
        padding:  2rem 0;
        
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1400px;
      display: flex;
         flex-direction: column;
        align-items: center;
        justify-content: space-between;
    
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const CardContainer = styled.div`
      width: 1400px;
     
      display: grid;
         
        grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
       
        // align-items: center;
        // justify-content: space-between;
        place-items: stretch center;
        
   
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

`

export const T = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    text-align: left;
    font-family: 'Nunito Sans', sans-serif;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;
export const H = styled.h1`


    color: #FFFFFF;
    font-size: 4rem;
    margin: 0 0 0.5rem 0;
    font-weight: bold;
    text-align: left;

    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0 1rem ;
      }
`;


const Lower = styled.div`
      display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
       margin-top: 5rem;

       background-color:  rgba(51, 51, 51, 1);
       padding: 2rem 1rem 1rem 1rem;
      //  width: 1300px;
      //  border-radius: 1rem;
      width: 100%;
`
const Hb = styled.h1`
    color: #FFFFFF;
    font-size: 3.5rem;
    font-weight: bold;
    text-align: left;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0 1rem ;
      }
`;

const Page3a = () => {
  
    return (
        <Sec id="rarity">

            <Width>
            <div>
                  <H>BENEFITS</H>
            </div>
                <CardContainer>
                    <Card />
                    <Card type2 content='NFT holders will get future allocation airdrops of EVERY protocol that is built on the ChimpWorld Ecosystem.' />
                    <Card content='NFT holders will get future allocation airdrops of EVERY protocol that is built on the ChimpWorld Ecosystem.'/>
                    <Card type2 content='Upcoming partnerships with reputable T1 protocols.' />
                    <Card content='You can be the leader and visionary of the next unicorn protocol deployed on #ChimpWorld.' />
                    <Card type2 content='Stake $CHIMP and receive $XCHIMP or $CHIMP-ETH LP tokens to earn a decent APY on ChimpReserves, making your $CHIMP work for you.' />
                </CardContainer>

                <WideCard />

            </Width>

        </Sec>
    )
}

export default Page3a





