import React from 'react'
import styled from 'styled-components';
import Tilt from 'react-parallax-tilt';

const T = styled.p`
    color: #FFF;

    font-size: 1.2rem;
 
    line-height: 1.4;
    text-align: center;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const Card = (props) => {
    const Box= styled(Tilt)`
        height: 12rem; 
        width: 11rem;   
        background-image: url('${props.img ? props.img : ''}');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: ${props.special ? '4.2rem 2rem 2rem 2rem ' : '2rem' };
        margin: 1rem 0;
   
    `;

    return (
        <Box>
           <T>
               {props.heading ? props.heading : 'Heading'}
           </T>
        </Box>
    )
}

export default Card
