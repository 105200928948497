import axios from "axios";
import config from "../../config";

export const getAllBidders = async () => {
  const headers = {
    "content-type": "application/json",
 
  };
  const graphqlQuery = {
    operationName: "fetchbids",
    query: `{
        users(first: 1000,orderBy:totalBidAmount,orderDirection:desc) {
          id
          totalBidAmount
          bids(first:1) {
            timestamp
          }
        }
       
      }`,
    variables: {},
  };

  const response = await axios({
    url: config.THEGRAPH,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });

  const data = response.data.data.users

  return data

};
