import React, { useState, useEffect } from "react";
import styled from "styled-components";
import bg from "./bg.png";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { BsBoxArrowUp } from "react-icons/bs";
import { Link, animateScroll as scroll } from "react-scroll";
import PoolInfo from "./components/PoolInfo";
import Balance from "../Balance/Balance";
import Web3 from "web3";
import { useWallet } from "use-wallet";
import config from "../../../../config";
import CHIMP_ABI from "../../../../config/abi/IERC20.json";
import MASTERCHEF from "../../../../config/abi/MASTERCHEF.json";
import BigNumber  from "bignumber.js";


const HomePage = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #000;
  padding: 5rem 0 5rem 0;
  background-attachment: fixed;

  @media only screen and (max-width: 768px) {
    min-height: 50rem;
    width: 100%;
    padding: 0rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
  }
`;

const Width = styled.div`
  width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1700px) {
    width: 90%;
  }
  @media only screen and (max-width: 1400px) {
    width: 95%;
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

const T = styled.p`
  color: #ffffff;
  font-weight: 250;
  font-size: 1.2rem;
  text-align: right;
  margin: 0 0 0.4rem 0;
  letter-spacing: 0.05rem;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;

const H = styled.p`
  font-weight: 200;
  color: #ffffff;
  font-size: 2rem;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;

const Hb = styled.p`
  font-weight: 400;
  color: #ffffff;
  font-size: 4rem;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ShowButton = styled(Link)`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  background: linear-gradient(97.53deg, #c429e0 18.8%, #812be1 89.56%);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  position: fixed;
  height: 4rem;
  width: 3rem;
  border-radius: 0.5rem;
  transition: 0.5s linear;
  right: 3rem;
  bottom: 10rem;
  display: grid;
  place-items: center;
  z-index: 7;
  @media only screen and (max-width: 768px) {
    right: 2rem;
    bottom: 5rem;
  }
`;

const Page1 = () => {
  const dispatch = useDispatch();
  const wallet = useWallet();
  const [showButton, setShowButton] = useState(false);

  const [tvl, setTVL] = useState(0);
  const [apr, setAPR] = useState(0);
  const [perDayChimp, setPerDayChimp] = useState("0");
  const [pendingChimp, setPendingChimp] = useState("0");
  const [myChimpBalance, setMyChimpBalance] = useState("0");
  
  const [stakedBalance, setStakedBalance] = useState("0");

  const [chimpContract, setChimpContract] = useState();

  const [isApproved, setApproved] = useState(false);

  
  const [masterChefContract, setMasterChefContract] = useState();

  const showButtonTop = () => {
    if (window.scrollY >= 600) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };



  const calculateAPR = (perblock,weight,tvl)=>{
    const BLOCKS_PER_YEAR = new BigNumber(10512000)
    const BLOCKS_PER_DAY = new BigNumber(28800)
    const cakeRewardPerBlock = new BigNumber(perblock || 1).times(new BigNumber(weight)) .div(new BigNumber(10).pow(18))
    const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR)
    const cakeRewardPerDay = cakeRewardPerBlock.times(BLOCKS_PER_DAY)

    let apy = cakeRewardPerYear;
    //new BigNumber(Web3.utils.fromWei(tvl.toString()) || 0);


    // if(totalValue.comparedTo(0) > 0){
    //   apy = apy.div(totalValue);
    // }


    return { _apy: apy, cakeRewardPerDay }
  }
  useEffect(() => {
    showButtonTop();
    // adding the event when scroll change background
    window.addEventListener("scroll", showButtonTop);
    loadContract();
  }, []);

  useEffect(() => {
    loadContract();
  }, [wallet.account]);

  const loadContract = () => {
    let web3;
    if (wallet.account) {
      web3 = new Web3(wallet.ethereum);
    } else {
      web3 = new Web3(config.rpc);
    }

    const masterChefContract = new web3.eth.Contract(
      MASTERCHEF,
      config.MASTERCHEF_CONTRACT
    );
    const chimpContract = new web3.eth.Contract(
      CHIMP_ABI,
      config.CHIMP_CONTRACT
    );
    setChimpContract(chimpContract);
    setMasterChefContract(masterChefContract);
  };

  useEffect(() => {
    if (chimpContract && masterChefContract) {
      loadData();
      setInterval(async () => {
        await loadData();
      }, [2000]);
    }
  }, [chimpContract, masterChefContract]);

  const loadData = async () => {


  
    const perBlock = await masterChefContract.methods.chimpPerBlock().call();
    const totalAllocPoint = await masterChefContract.methods.totalAllocPoint().call();
    const poolInfo = await masterChefContract.methods.poolInfo(0).call();
    const allocPoint = new BigNumber(poolInfo.allocPoint);

    const weight = new BigNumber(allocPoint).div(totalAllocPoint)

    const _tvl = poolInfo.tvl;
    
    setTVL(Web3.utils.fromWei(_tvl));
    const aprData = calculateAPR(new BigNumber(perBlock), new BigNumber(weight), new BigNumber(_tvl));
    const _apr = aprData._apy;
    const farmAPY = _apr && _apr.times(new BigNumber(100)).toNumber().toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    const cakeRewardPerDay = aprData && aprData.cakeRewardPerDay.times(new BigNumber(100)).toNumber().toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  
    setAPR(farmAPY)

    setPerDayChimp(cakeRewardPerDay)

    if (wallet.account) {
      const chimpBalance = await chimpContract.methods
        .balanceOf(wallet.account)
        .call();
      setMyChimpBalance(chimpBalance);


      const _isApproved = await chimpContract.methods.allowance(wallet.account,config.MASTERCHEF_CONTRACT).call();
      setApproved(Number(Web3.utils.fromWei(_isApproved))>0)
      const myPendingReward = await masterChefContract.methods.pendingCHIMP(0,wallet.account).call()
      setPendingChimp(myPendingReward);


      const _stakedBalance = await masterChefContract.methods.userInfo(0,wallet.account).call();
      setStakedBalance(_stakedBalance.amount)

      
    }
  };

  return (
    <HomePage id="home">
      <Width>
        <div style={{ margin: "5rem 0" }}>
          <H style={{ textAlign: "center" }}>$CHIMP</H>
          <Hb style={{ margin: "0.4rem 0 0 0" }}>STAKING POOL</Hb>
        </div>

        <PoolInfo tvl={tvl} apr={apr} />

        <Balance
        chimpContract={chimpContract}
        masterChefContract={masterChefContract}
          myBalance={myChimpBalance}
          perDayChimp={perDayChimp}
          stakedBalance={stakedBalance}
          isApproved={isApproved}
          pendingChimp={pendingChimp}
        />
      </Width>

      {showButton ? (
        <ShowButton type="button" to="home" spy={true} smooth={true}>
          <BsBoxArrowUp size="2rem" />
        </ShowButton>
      ) : null}
    </HomePage>
  );
};

export default Page1;
