import React, { useState } from "react";
import styled from "styled-components";
import Web3 from "web3";
import bg from "./card.png";

const StylePool = styled.div`
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1000px;
  padding: 5rem 5rem 3rem 5rem;
  display: grid;
  place-items: center;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
`;

const H = styled.p`
  font-weight: 200;
  color: #ffffff;
  font-size: 1.75rem;
  // text-align: center;

  margin: 0 0 2rem 0;

  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
    margin: 1rem 0 0 0;
  }
`;

const Lower = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;

  @media only screen and (max-width: 900px) {
    width: 95%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Button = styled.button`
  background: linear-gradient(91.69deg, #c429e0 -12.45%, #662aca 100.44%);
  border: none;
  width: 80%;
  margin-top:10px;
  padding: 0 0.5rem;
  color: #fff;
  height: 3.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;

  // font-family: 'Poppins', sans-serif;
  
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    
    font-size: .75rem;
  }
`;
const Rewards = ({ perDayChimp, pendingChimp ,onHarvest }) => {
  const [isLoading, setLoading] = useState(false);

  const handleHarvest = async () => {
    setLoading(true);
    await onHarvest()
    setLoading(false);
  };
  return (
    <StylePool>
      <H>MY REWARDS</H>
      <Lower>
        <div style={{ margin: "2rem 0 0 0" }}>
          <p
            style={{
              color: "rgba(255,255,255,0.8)",
              fontSize: "1.3rem",
              letterSpacing: "0.1rem",
              margin: "0 0 0.75rem 0",
              textTransform:"uppercase"
              // fontFamily: 'Poppins',
            }}
          >
            Pending Reward
          </p>
          <H>{Web3.utils.fromWei(pendingChimp)} CHIMP</H>
        </div>
        <div style={{ margin: "2rem 0 0 0" }}>
          <p
            style={{
              color: "rgba(255,255,255,0.8)",
              fontSize: "1.3rem",
              letterSpacing: "0.1rem",
              textTransform:"uppercase",

              margin: "0 0 0.75rem 0",
              // fontFamily: 'Poppins',
            }}
          >
            EST $CHIMP PER DAY
          </p>
          <H>{perDayChimp} CHIMP</H>
        </div>
      </Lower>

      <Button
        style={{
          opacity: Number(pendingChimp) == 0 ? 0.4 : 1,
        }}
        disabled={isLoading || Number(pendingChimp) == 0}
        onClick={() => {
          handleHarvest();
        }}
      >
        {isLoading ? "Loading..." : "Harvest Reward"}
      </Button>
    </StylePool>
  );
};

export default Rewards;
