import React, { useEffect, useState } from "react";
import styled from "styled-components";
import balance from "./balance.png";
import { Input } from "../../../../PublicSale/components/Page1/components/SalePeriod";
import Web3 from "web3";
import { useWallet } from "use-wallet";
import config from "../../../../../config";

const StylePool = styled.div`
  background-image: url(${balance});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1000px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem 0 0 0;

  @media only screen and (max-width: 1100px) {
    width: 99%;
  }

  @media only screen and (max-width: 600px) {
    background-image: none;
  }
`;

const H = styled.p`
  font-weight: 200;
  color: #ffffff;
  font-size: 1.75rem;
  // text-align: center;

  margin: 0 0 2rem 0;

  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
    margin: 1rem 0 0 0;
  }
`;

const Lower = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
`;
const Box = styled.div`
  background-color: #312a3f;
  width: 80%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Button = styled.button`
  background: linear-gradient(91.69deg, #c429e0 -12.45%, #662aca 100.44%);
  border: none;
  width: 80%;
  margin-top:10px;
  padding: 0 0.5rem;
  color: #fff;
  height: 3.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;

  // font-family: 'Poppins', sans-serif;
  
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    
    font-size: .75rem;
  }
`;

const TabButton = styled.button`
  background: linear-gradient(91.69deg, #662aca -12.45%, #c429e0 100.44%);
  border: none;
  width: 100%;
  padding: 0 2rem;
  color: #fff;
  text-transform: uppercase;

  height: 4rem;
  margin-right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  // margin: 4rem 0 3rem 0;
  // font-family: 'Poppins', sans-serif;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 1.5rem;
    padding: 0 1rem;
  }
`;
const Container = styled.div`
  width: 100%;
  // display: grid;
  // place-items: center;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Small = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`

const BalanceCard = ({
  myBalance,
  stakedBalance = "0",
  masterChefContract,
  chimpContract,
  isApproved,
}) => {
  const [isDepositMode, setDepositMode] = useState(true);
  const [inputAmount, setInputAmount] = useState();
  const [isLoading, setLoading] = useState(false);

  const wallet = useWallet();

  const handleApprove = async () => {
    setLoading(true);
    try {
      await chimpContract.methods
        .approve(
          config.MASTERCHEF_CONTRACT,
          "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        )
        .send({
          from: wallet.account,
        });
    } catch (err) {
      console.error("onHarvest", { err });
    }
    setLoading(false);
  };

  const handleDeposit = async () => {
    if (!inputAmount || Number(inputAmount) == 0) {
      alert("Please Enter Valid Amount");
      return;
    }

    setLoading(true);
    try {
      await masterChefContract.methods
        .deposit(0, Web3.utils.toWei(inputAmount.toString()))
        .send({
          from: wallet.account,
        });
    } catch (err) {
      console.error("handleDeposit", err);
    }
    setLoading(false);
  };

  const handleWithdraw = async () => {
    if (!inputAmount || Number(inputAmount) == 0) {
      alert("Please Enter Valid Amount");
      return;
    }
    setLoading(true);
    try {
      await masterChefContract.methods
        .withdraw(0, Web3.utils.toWei(inputAmount.toString()))
        .send({
          from: wallet.account,
        });
    } catch (err) {
      console.error("handleDeposit", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    wallet.connect();
  }, []);
  const renderButton = () => {
    let text = "";
    let onClick;

    if (wallet.account) {
      if (isDepositMode) {
        if (!isApproved) {
          text = "APPROVE CONTRACT";
          onClick = handleApprove;
        } else {
          text = "Deposit";
          onClick = handleDeposit;
        }
      } else {
        text = "Withdraw";
        onClick = handleWithdraw;
      }
    } else {
      text = "Connect Wallet";
      onClick = wallet.connect;
    }

    return (
      <Button
        disabled={isLoading}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {isLoading ? "Loading..." : text}
      </Button>
    );
  };

  useEffect(()=>{
    setInputAmount("")
  },[isDepositMode])
  return (
    <StylePool>
      <H style={{margin:'0 0 3rem 0',color:'rgba(255,255,255)',letterSpacing:'0.3rem'}}>MY BALANCE</H>

      <div style={{ margin: "1rem 0 4rem 0", display: "flex" }}>
        <TabButton
          style={{ opacity: isDepositMode ? 1 : 0.4 }}
          onClick={() => {
            setDepositMode(true);
          }}
        >
          Deposit
        </TabButton>

        <TabButton
          style={{ opacity: isDepositMode ? 0.4 : 1 }}
          onClick={() => {
            setDepositMode(false);
          }}
        >
          Withdraw
        </TabButton>
      </div>
      <Container>
        <Small
          style={{
            // display: "flex",
            // width: "80%",
            // justifyContent: "space-between",
            // paddingLeft: 20,
            // paddingRight: 20,
          }}
        >
          <a 
            className="hoverable"
            onClick={()=>{
              setInputAmount(Web3.utils.fromWei(isDepositMode ? myBalance : stakedBalance))
            }}
            style={{
              width: "80%",
              color: "rgba(255,255,255,0.8)",
              textAlign: "left",
              margin: "0 0 0.5rem 0",
            }}
          >
            MAX
          </a>
          <p
            style={{
              width: "80%",
              color: "rgba(255,255,255,0.8)",
              textAlign: "right",
              margin: "0 0 0.5rem 0",
            }}
          >
            {Web3.utils.fromWei(isDepositMode ? myBalance : stakedBalance)}{" "}
            CHIMP
          </p>
        </Small>
        <Box>
          <Input
            type="number"
            min="0"
            // style={{width:'20rem'}}
            value={inputAmount}
            placeholder={
              isDepositMode ? "ENTER CHIMP AMOUNT" : "ENTER WITHDRAW AMOUNT"
            }
            onChange={(e) => {
              setInputAmount(e.target.value);
            }}
          />
        </Box>
      </Container>
      {renderButton()}
    </StylePool>
  );
};

export default BalanceCard;
