import React from 'react'
import './App.css'
import Navbar from './components/Navbar/Navbar'
import Home from './Page/Home/Home'
import NFTPage from './Page/NFTPage/NFTPage'
import PublicSale from './Page/PublicSale/PublicSale'
import Footer from './Page/Foot/Footer'
import Pools from './Page/Pools/Pools'
import Voting from './Page/Voting/Voting'
import Claim from './Page/Claim/Claim'

import ComingSoon from './Page/ComingSoon/ComingSoon'
import { Routes, Route } from "react-router-dom";

const App = () => {

  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='nft' element={
          // <ComingSoon />
          <NFTPage />
        } />
        <Route path='sale' element={
          // <ComingSoon />
        <PublicSale />
        } />
        <Route path='pool' element={
          // <ComingSoon />
          <Pools />
          } />

<Route path='claim' element={
          // <ComingSoon />
          <Claim />
          } />
        <Route path='vote' element={<Voting />} />
      </Routes>
      <Footer />
   </>
  )
}

export default App
