import React from 'react'
import styled from 'styled-components'
import './Footer.css'
import bg from './foot.png'
import { Image } from '../../components/Navbar/Navbar'
import { T } from '../Home/components/Page2/Page2'
import dis from '../../components/Navbar/dis.svg'
import twi from '../../components/Navbar/Twitter.svg'
import med from '../../components/Navbar/tele.svg'
import logo from '../../components/Navbar/logo.png'
import email from '../../components/Navbar/email.svg'

import { Outlet, Link } from "react-router-dom";

const StyledFooter = styled.div`
  
`
const IconContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    width: 15rem;
    @media only screen and (max-width: 1200px) {
        margin: 1rem 0 0 0;
    }
    @media only screen and (max-width: 998px) {
        width: 12rem;
    }
    @media only screen and (max-width: 768px) {
        width: 9rem;
    }
`
const Left = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    flex-direction: column;
    margin-left: 3rem;
    @media only screen and (max-width: 768px) {
        margin-left: 0;
    }
`

const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    margin-right: 3rem;
    @media only screen and (max-width: 768px) {
        margin: 2rem 0 0 0;
    }
`
const Link1 = styled(Link)`
    
    width: 7rem;
    height: 6rem;
   cursor: pointer;
   
    // @media only screen and (max-width: 768px) {

    //     width: rem;
    //     height: 4rem;
    // }
    `
    const Link2 = styled(Link)`
  display: flex;
  margin: 0;
//   font-family:'poppins';

  align-items: center;
  text-decoration: none;
  color: #FFF !important;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    
  }

`;

const Footer = () => {
    return (
        <StyledFooter className="foot">

            
                 
               
           

            <Link1 style={{margin:"0"}} to="home" spy={true} smooth={true} ><img src={logo} alt="Logo" style={{width:"100%",height:"100%"}} /></Link1>

            <ul className="list" style={{margin:'2rem 0'}}>
                <Link2 to="/" >HOME</Link2>
                <Link2 to="/nft" >NFT AUCTION</Link2>
                <Link2 to="/sale" >GET $CHIMP</Link2> 
                <Link2 to="/claim">CLAIM REFUND</Link2>

                <Link2 to="/pool" >STAKE</Link2>
                <Link2 to="/docs" >DOCS</Link2>
                <Link2 to="/vote" >VOTE</Link2>
            </ul> 

            <IconContainer>
                <a href='https://discord.com/invite/chimpdao' target='_blank'>
                    <Image
                    src={dis}
                    whileHover={{ scale: 1.1,boxShadow:"0 0 25px #C92CE2",borderRadius:'50%'}}
                    whileTap={{ scale: 0.7 }}
                    alt="Logo"  
                    />
                </a>
                <a href='https://twitter.com/chimpdao' target='_blank'>
                    <Image src={twi} alt="Logo" 
                    whileHover={{ scale: 1.1,boxShadow:"0 0 25px #C92CE2",borderRadius:'50%'}}
                    whileTap={{ scale: 0.7 }}
                    />
                </a>
                <a href='https://t.me/ChimpDAO' target='_blank'>
                    <Image src={med} alt="Logo" 
                    whileHover={{ scale: 1.1,boxShadow:"0 0 25px #C92CE2",borderRadius:'50%'}}
                    whileTap={{ scale: 0.7 }}
                    />
                </a>
                {/* <img src="https://img.icons8.com/material-outlined/24/000000/new-post.png"/> */}
                <a href='mailto://support@chimpdao.com' target='_blank'>
                {/* <img src="https://img.icons8.com/ios/50/000000/new-post.png"/> */}
                <Image src={email} alt="Logo" 
                   
                   whileHover={{ scale: 1.1,boxShadow:"0 0 25px #C92CE2",borderRadius:'50%'}}
                   whileTap={{ scale: 0.7 }}
                   />
                </a>
                </IconContainer>
           
        </StyledFooter>
    )
}

export default Footer
