import React from 'react'
import styled from 'styled-components'
import Tilt from 'react-parallax-tilt';
import bg from './bg2.png'
import { motion } from 'framer-motion'
import c1 from './c1.png'
import c2 from './c2.png'
import c3 from './c3.png'

const Sec = styled.section`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    background-color: #000;
    width: 100%;
    padding: 10rem 0 5rem 0;
    background-image: url('${bg}');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1500px) {
      background-size: auto 100%;
      background-position: center center;
  }
  
    
    @media only screen and (max-width: 768px) {
        
        padding:  2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1400px;
      // display: flex;
        
      //   align-items: center;
      //   justify-content: space-between;
    
    @media only screen and (max-width: 1500px) {
        width: 90%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
        width: 95%;
    }

`

const Left = styled(motion.div)`
        
        @media only screen and (max-width: 998px) {
            width: 85%;
        }

    @media only screen and (max-width: 768px) {
     
        width: 95%;
      
      }
`;

const Right = styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 5rem 0 0 0;

      @media only screen and (max-width: 768px) {
        width: 99%; 
      
      }
`;

export const T = styled.p`
    color: #FFF;
      font-weight: 300;
    font-size: 1.2rem;
    // font-family:'poppins';

    margin: 1rem 0 2rem 0;
    text-align: left;
    line-height: 1.8;
      max-width: 1300px;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
        margin: 1rem 1rem 2rem 1rem ;
      }
`;

export const H = styled.h1`


    color: #FFFFFF;
    font-size: 4rem;
    margin: 0 0 0.5rem 0;
    font-weight: bold;
    text-align: left;

    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0 1rem ;
      }
`;

const Hb = styled.h1`
    color: #FFFFFF;
    font-size:4rem;
    font-weight: bold;
    text-align: left;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0 1rem ;
      }
`;

const Flex =  styled.div`
  width: 100%;
  display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3rem 0 0 0;

    @media only screen and (max-width: 998px) {
      flex-direction: column;
     
  }
    
`
const Card1 = styled.div`
  width: 19rem;
  height: 25rem;
  font-family:'poppins';

  background-image: url('${c1}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #FFF;
    padding: 3rem 1.5rem;


 
`
const Card2 = styled.div`
  width: 19rem;
  height: 25rem;
  font-family:'poppins';

  background-image: url('${c2}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #FFF;
    padding: 2rem 1.5rem;
    @media only screen and (max-width: 998px) {
      margin: 1.5rem 0;
     
  }
`
const Card3 = styled.div`
  width: 19rem;
  height: 25rem;
  font-family:'poppins';

  background-image: url('${c3}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #FFF;
    padding: 3rem 1.5rem;
`

const Page2 = () => {
  
    return (
        <Sec id="rarity">

            <Width>
                <Left >
                  <H>WHAT IS</H>
                  <Hb>CHIMPDAO</Hb>
                  <T>
                  To ape is the very core of DeFi degens - getting into new projects with
                   the hopes of being the first in something new. The ultimate vision of ChimpDAO 
                   is to encapsulate the core of being an ape with a form of governance, transparency
                    and utility - we aim to allow the collective ChimpDAO to ape together in unity,
                     in platforms, projects and visions that would otherwise be hidden gems.
                     ChimpDAO is the place where new and exciting 
                  platforms or protocols are collectively ideated, developed and executed.
                  </T>
                </Left>
            
                <Right>
                  
                  <H>WHY</H>
                  <Hb>CHIMPDAO</Hb>
                  <Flex>
                    <Card1>
                      ChimpDAO was thought up by a group of like-minded individuals
                       who bring together a strong technological and developmental 
                       skill set with a common desire to create something that is 
                       not only sustainable, but something beneficial to all holders 
                       of the $CHIMP token
                    </Card1>
                    <Card2>
                      ChimpDAO is aimed at rallying a community based on the idea of a
                       common goal - to be builders. ChimpDAO’s main goal is to allow 
                       the community to leverage on the developmental capabilities of 
                       ChimpDAO’s initial members and the vision and leadership of 
                       the pioneers and its participants, while being able to have a 
                       direct contribution in exactly what will be built.
                    </Card2>
                    <Card3>
                      All participants will be part of a collective mind where upcoming 
                      new projects and/or protocols can be ideated, incubated and 
                      technologically developed.
                    </Card3>
                  </Flex>
                </Right>

            </Width>

            {/* <Lower>
                <H style={{textAlign:"center"}}>Stay ahead of the king!</H>
                <T style={{textAlign:"center"}}>Subscribe to receive exclusive updates.</T>
                <Row>
                    <Input placeholder="Enter email address"></Input>
                    <UpdatedButton
                        type="button"
                        whileHover={{ scale: 1.1,boxShadow:"0 0 25px #ff7b00"}}
                    whileTap={{ scale: 0.7 }}
                    >SIGN UP</UpdatedButton>
                </Row>
                <Image2>
                    <img src='' alt="" style={{width:"100%",height:"100%"}} />
                </Image2>
            </Lower> */}

        </Sec>
    )
}

export default Page2





