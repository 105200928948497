import React, { useEffect, useState } from "react";
import Page1 from "./components/Page1/Page1";
import Page7 from "./components/Page7/Page7";
import Page6a from "./components/Page6a/Page6a";
import Page6b from "./components/Page6b/Page6b";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import config from "../../config";
import AuctionABI from "../../config/abi/AUCTION.json";

const NFTPage = () => {
  const [auctionWriteContract, setAuctionWriteContract] = useState();
  const [auctionReadContract, setAuctionReadContract] = useState();
  const [bids, setBids] = useState([]);

  const wallet = useWallet();

  useEffect(() => {
    wallet.connect();
    loadReadContract();
  }, []);

  const loadReadContract = () => {
    const web3 = new Web3(config.rpc);
    const auctionContract = new web3.eth.Contract(
      AuctionABI,
      config.AUCTION_CONTRACT
    );
    setAuctionReadContract(auctionContract);
  };

  useEffect(() => {
    if (wallet.account) {
      loadWriteContract();
    }
  }, [wallet.account]);

  const loadWriteContract = async () => {
    const web3 = new Web3(wallet.ethereum);
    const auctionContract = new web3.eth.Contract(
      AuctionABI,
      config.AUCTION_CONTRACT
    );
    setAuctionWriteContract(auctionContract);
  };

  return (
    <>
      <Page1      />
      <Page7
        auctionReadContract={auctionReadContract}
        auctionWriteContract={auctionWriteContract}
        onBidsFetched={(_bids)=>{
          setBids(_bids)
        }}
      />
      <Page6a  bids={bids}/>
      <Page6b />
    </>
  );
};

export default NFTPage;
